import React from 'react';
import Typography from '@mui/material/Typography';
import descData from "../desc.json"
import SectionContainer from '../../containers/SectionContainer';
import { Grid, Box} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Avatar from "../../assets/avatar"


const About = () => {
    const classes = useStyles();
    return (
        <SectionContainer id="about" title={descData.aboutSectionTitle}>
        <Paper elevation={16} sx={{p:1}}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={7}
                    className={classes.gridItemWrapper}
                    style={{ flexDirection: "column", alignItems: "space-around" }}
                >
                    <Box mb={4}>
                        <Typography variant="body1">{descData.aboutDescContent}</Typography>
                    </Box>
                </Grid>
                    <Grid item xs={12} md={5} className={classes.gridItemWrapper}>
                        <Avatar />
                    </Grid>
            </Grid>
            </Paper>
        </SectionContainer>
    );
};

const useStyles = makeStyles(() => ({
    gridItemWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export default About;