import React, { useRef  } from 'react';
import descData from "../desc.json"
import SectionContainer from '../../containers/SectionContainer';
import emailjs from '@emailjs/browser';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import * as yup from "yup";



const Contact = () => {
    const form = useRef();
    let schema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string().email(),
        text: yup.string().required()
      });
    const sendEmail = (e) => {
      e.preventDefault();
        if (schema.validate(form.current)) {
      emailjs.sendForm('service_gstprie', 'template_0e0u7gf', form.current, 'kVUfjTXYlCPK8AcJs')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    }
    };

    return (
        <SectionContainer id="contact" title={descData.contactSectionTitle}>
        <Container maxWidth="md">
               <Box overflow="hidden" style={{ position: "relative", minHeight: "300px" }}>
                <form ref={form} onSubmit={sendEmail}>
                <TextField
                    required
                    name="user_name"
                    label="Name"
                    type="name"
                    inputRef={form}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    required
                    name="user_email"
                    label="Email"
                    type="email"
                    inputRef={form}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    required
                    name="message"
                    multiline
                    minRows={4}
                    label="How Can I Help You?"
                    type="text"
                    inputRef={form}
                    variant="filled"
                    margin="normal"
                    fullWidth
                />
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button 
                        type="submit" 
                        variant="contained"
                        fullWidth
                    >
                            Send
                    </Button>
                    </Box>
                </form>
                </Box>
                </Container>
        </SectionContainer>



)};

export default Contact;