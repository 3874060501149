import { createTheme } from '@mui/material/styles';

export const myTheme = createTheme ({
  palette: {
    background: {
      default: '#DED2B7',
      paper: '#CCB798',
    },
    primary: {
      main: '#17b09d',
      contrastText: '#eeeeee',
    },
    text: {
      secondary: '#969696',
      primary: '#423c35',
    },
    secondary: {
      main: '#90828F',
      contrastText: '#eeeeee',
    },
    error: {
      main: '#F44538',
    },
    warning: {
      main: '#C1A741',
    },
    info: {
      main: '#77C8D9',
    },
    success: {
      main: '#4DC0B5',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans sarif',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontSize: 14,
    fontWeightBold: 700,
    fontWeightMedium: 500,
    htmlFontSize: 15,
  },
  components: {
    MuiContainer: {
      variants: [
        {
          props: {variant: 'mainContent'},
          style: {
            textAlign: 'center',
            width: 'Auto',
          },
        }
      ]
    },
    MuiBox: {
      variants: [
        {
          props: {variant: 'bigBox'},
          style: {
            textAlign: 'center',
            width: 'Auto',
          }
        }
      ]
    },
  },
});