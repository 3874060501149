import React from "react";
import Services from "./sections/services/Services";
import About from "./sections/about/about";
import Experience from "./sections/experience/Experience";
import Contact from "./sections/contact/Contact";
import { motion } from "framer-motion";
import { Container } from "@mui/system";
import { CssBaseline } from "@mui/material";
import { myTheme } from "./assets/theme/theme";
import { ThemeProvider } from '@mui/material/styles';
import Header from "./header";


const App = () => {

return (
<ThemeProvider theme={myTheme}>
<CssBaseline />
<Header />
<Container id="Home" sx={{pt:'30px'}}>
<motion.main
 initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
 whileInView={{
 transition: {
    type: "spring",
    bounce: 0.4,
    duration: 0.8
  }
 }}
 viewport={{ once: false, amount: 0.4 }}
>
    <About />
    <Services />
    <Experience />
    <Contact />
</motion.main>
</Container>
</ThemeProvider>
)};

export default App;