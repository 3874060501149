import React from 'react';
import descData from "../desc.json"
import SectionContainer from '../../containers/SectionContainer';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';


const Experience = () => {
    
    return (
        <SectionContainer id="experience" title={descData.experienceSectionTitle}>
        <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '25pt'}}>
                <Paper elevation={16} sx={{textAlign: 'center', width:'80%'}}>
                    <Typography variant="body1">
                        {descData.experienceSectionSummary}
                    </Typography>
                </Paper>
                </Box>

                <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '25pt'}}>
                <Paper elevation={16} sx={{textAlign: 'center', width:'80%'}}>
                    <Typography variant="body1">
                        {descData.experienceSectionSkills}
                    </Typography>
                </Paper>
                </Box>
        
        </SectionContainer>



)};



export default Experience;