import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardHeader, Divider, Grid } from '@mui/material';
import { borders } from '@mui/system';
import descData from "../desc.json"
import SectionContainer from '../../containers/SectionContainer';

const CardItem = styled(Paper)(({ theme }) => ({
        maxWidth: '350px',
        padding: '10px',
        }));

const MainCardHeader = styled(CardHeader)(({ theme }) => ({
   'border-bottom': '1px solid', 
  
    minWidth: '200px',
    textAlign: 'center',
}));

  export default function Services(props) {
    const { skills } = props;
    
    return (
            <SectionContainer id="services" title={descData.servicesSectionTitle}>
                <Box sx={{display: 'flex', justifyContent: 'center', marginBottom: '25pt'}}>
                <Paper elevation={16} sx={{textAlign: 'center', width:'50%'}}>
                    <Typography variant="body1">
                        {descData.servicesSectionSummary}
                    </Typography>
                </Paper>
                </Box>
                <Grid container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    
                >
                <Grid item  xs="auto">
                <CardItem elevation={16}>
                <CardActionArea>
                   <MainCardHeader title={descData.webSvcHeader} titleTypographyProps={{variant: 'h6'}} />
                    <CardContent sx={{ typography: 'body2'}}>
                        {descData.webSvcSummary}
                    </CardContent>
                </CardActionArea>
                </CardItem>
                </Grid>
                <Grid item  xs="auto">
                <CardItem elevation={16}>
                <CardActionArea>
                    <MainCardHeader title={descData.deviceSvcTitle} titleTypographyProps={{variant: 'h6'}}/>
                    <CardContent sx={{ typography: 'body2'}}>
                        {descData.deviceSvcSummary}
                     </CardContent>
                </CardActionArea>
                </CardItem>
                </Grid>
                <Grid item  xs="auto">
                <CardItem elevation={16}>
                <CardActionArea>
                    <MainCardHeader title={descData.backupSvcTitle} titleTypographyProps={{variant: 'h6'}}/>
                    <CardContent sx={{ typography: 'body2'}}>
                        {descData.backupSvcSummary}
                     </CardContent>
                </CardActionArea>
                </CardItem>
                </Grid>
                
                <Grid item xs="auto">
                <CardItem elevation={16}>
                <CardActionArea>
                    <MainCardHeader title={descData.cloudSvcTitle} titleTypographyProps={{variant: 'h6'}}/>
                    <CardContent sx={{ typography: 'body2'}}>
                        {descData.cloudSvcSummary}
                    </CardContent>
                </CardActionArea>
                </CardItem>
                </Grid>
                <Grid item xs="auto">
                <CardItem elevation={16}>
                <CardActionArea>
                    <MainCardHeader title={descData.securitySvcTitle} titleTypographyProps={{variant: 'h6'}}/>
                    <CardContent sx={{ typography: 'body2'}}>
                        {descData.securitySvcSummary}
                    </CardContent>
                </CardActionArea>
                </CardItem>
                </Grid>
                <Grid item xs="auto">
                <CardItem elevation={16}>
                <CardActionArea>
                    <MainCardHeader title={descData.hostingSvcTitle} titleTypographyProps={{variant: 'h6'}}/>
                    <CardContent sx={{ typography: 'body2'}}>
                        {descData.hostingSvcSummary}
                    </CardContent>
                </CardActionArea>
                </CardItem>
                </Grid>
                <Grid item xs="auto">
                <CardItem elevation={16}>
                <CardActionArea>
                    <MainCardHeader title={descData.consultSvcTitle} titleTypographyProps={{variant: 'h6'}}/>
                    <CardContent sx={{ typography: 'body2'}}>
                        {descData.consultSvcSummary}
                    </CardContent>
                </CardActionArea>
                </CardItem>
                </Grid>
                <Grid item xs="auto">
                <CardItem elevation={16}>
                <CardActionArea>
                    <MainCardHeader title={descData.networkSvcTitle} titleTypographyProps={{variant: 'h6'}}/>
                    <CardContent sx={{ typography: 'body2'}}>
                        {descData.networkSvcSummary}
                    </CardContent>
                </CardActionArea>
                </CardItem>
                </Grid>
                <Grid item xs="auto">
                <CardItem elevation={16}>
                <CardActionArea>
                    <MainCardHeader title={descData.docSvcTitle} titleTypographyProps={{variant: 'h6'}}/>
                    <CardContent sx={{ typography: 'body2'}}>
                        {descData.docSvcSummary}
                    </CardContent>
                </CardActionArea>
                </CardItem>
                </Grid>
                </Grid>
            </SectionContainer>
  
    )};