import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from "@mui/material/AppBar";
import Container from '@mui/material/Container';
import Toolbar from "@mui/material/Toolbar";
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';


import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Link } from "react-scroll";

function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0
    });
  };

  const sections = [
    { title: 'About Me', url: 'about' },
    { title: 'Services', url: 'services' },
    { title: 'Experience', url: 'experience' },
    { title: 'Contact Me', url: 'contact' },
  ];

  const scrollLink = React.forwardRef((props, ref) => (
  <Link {...props}/>));

export default function Header(props) {

    return (
    <Container>
    <ElevationScroll>
        <AppBar>
            <Toolbar
            component="nav"
            variant="dense"
            sx={{ justifyContent: 'Center', overflowX: 'auto' }}>
                
                {sections.map((section) => (
                  
                <MenuItem component={scrollLink}
                      activeClass="active"
                      to={section.url}
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      disablePadding
                      >
                      {section.title}
                </MenuItem>
                
            ))}
            
            </Toolbar>
        </AppBar>
        </ElevationScroll>
        
    </Container>
)}

Header.propTypes = {
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ).isRequired,
  };
